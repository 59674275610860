module.exports = {
  HOME: 'หน้าแรก',
  WALLET: 'กระเป๋าตังค์',
  SERVICE: 'ฝ่ายบริการลูกค้า',
  MY: 'ฉัน',
  APPLYMONEY: 'ยอดเงินกู้(บาท)',
  UNIT: 'บาท',
  BORRORWMONEYTIME: 'ระยะเวลากู้ยืม',
  EVERYISSUE: 'การชำระคืนต่อเดือน',
  DAYRATE: 'อัตราดอกเบี้ยรายเดือน',
  MOTHLYINTEREST: 'ดอกเบี้ยรายเดือน',
  CONSENT: 'ยินยอม',
  AGREEMENTENT: '《สัญญาเงินกู้ 》',
  ATONECBORROWMONEY: 'ยืมเงินทันที',
  CONSENTPROTOCOL: 'โปรดตรวจสอบข้อตกลงนี้',
  LOANAPPLICATION: 'สมัครสินเชื่อ',
  CONSENTBORROWMESSAGE: 'ยืนยันข้อมูลการทำรายการ',
  BORRORWMONEY: 'จำนวนเงินกู้',
  ACCOUNTBANK: 'บัญชีธนาคาร',
  PAYEEBOX: 'หมายเลขบัญชีธนาคาร',
  SUBMITBORROWTEXT: 'ยืนขอกู้',
  PLEACHECKPROTOCOL: 'โปรดตรวจสอบข้อตกลงนี้',
  MONTHS: 'เดือน',
  USERNAME: 'ชื่อผู้ใช้',
  PASSWORD: 'รหัสผ่าน',
  SUBMIT: 'ส่ง',
  CHECKUSERNMAE: 'ข้อมูลผู้ใช้ไม่',
  CHECKPASSWORD: 'รหัสผ่านไม่สามารถว่างได้',
  REGISTER: 'ลงทะเบียน',
  FORGET: 'ลืมรหัสผ่าน',
  NOTCHINA: 'ใช้ภาษาอังกฤษและเลขเท่านั้น',
  LIJIRECHARGE: 'เติมเงินตอนนี้',
  CANCEL: 'ยกเลิก',
  RECHARGEMONEY: 'เติมเงิน',
  WITHDRAWDEPOSIT: 'ถอนเงินสด',
  WITHDRAWDEPOSITPASSWORD: 'รหัส ถอนเงิน',
  INPUTWITHDRAWDEPOSITPASSWORD: 'โปรดกรอกหมายเลขถอนเงิน',
  WITHDRAWDEPOSITMONEYNULL: 'หมายเลขถอนเงินไม่สามารถ ว่างได้',
  WITHDRAWDEPOSITNULL: 'หมายเลขรหัสไม่สามารถว่างได้',
  MYDATA: 'ประวัติของฉัน',
  MYBORROW: 'การกู้ยืมของฉัน',
  MYREFUND: 'การชำระของฉัน',
  OPENVIP: 'เปิดVIP',
  CHANGEPASSWORD: 'แก้ไขรหัสผ่าน',
  LOGOUT: 'ออกจากระบบ',
  MSUTDATAFULL: 'ต่อไปนี้เป็นข้อมูลที่จำเป็นในการกรอกเพื่อสมัครสินเชื่อ',
  IDENTITYDATA: 'ข้อมูลประจำตัว',
  UNDERSTANDBASICINFORMATION: 'โปรดแจ้งสถานะของคุณ',
  DATAMESSAGE: 'ข้อมูลส่วนตัว',
  RECEIPTBANKCARD: 'หมายเลขบัญชีบัตรผู้รับ',
  PHONENUMVERIFY: 'รหัสยืนยันทางโทรศัพท์',
  VERIFYSELFPHONENUM: 'โปรดยืนยันเบอร์โทรศัพท์ของคุณ',
  INCREASETHEPASSRATE: 'โปรดกรอกข้อมูล ดังต่อไปนี้เพื่อเพิ่มวงเงินในการกู้สินเชื่อ เพื่อดำเนินการอุนมัติ',
  FURTHERINFORMATION: 'ข้อมูลเพิ่มเติม',
  REVIEWTHEPASSRATE: 'เพิ่มโอกาศต่างๆในการอนุมัติ',
  FILLED: 'กรอกเรียบร้อย',
  FILLINTRUEINFORMATION: 'กรอกข้อมูลจริงเท่านั้นจึงจะผ่านการอนุมัติ',
  NAME: 'ชื่อ',
  IDCARDNUMBER: 'เลขประจำตัวบัตรประชาชน',
  UPLOADIDPROTO: 'อัพโหลดรูปถ่ายบัตรประจำตัว',
  IDPOSITIVEPROTO: 'หน้าบัตรประจำตัวบัตรประชาชน',
  IDONTHEBRECKPROTO: 'หลังบัตรประจำตัวบัตรประชาชน',
  HANDTAKEIDPROTO: 'ยืนถือบัตรประจำตัวบัตรประชน',
  ENTITYNAME: 'ชื่อบริษัท',
  POSITION: 'ตำแหน่ง',
  ENTITYPHONE: 'เบอร์ติดต่อ บริษัท',
  WORKAGE: 'ระยะเวลาในการทำงาน',
  CURRENTRESIDENCEDETAILS: 'ที่อยู่ปัจจุบัน',
  CONTACTOFIMMEDIATEFAMILY: 'ติดต่อญาติพี่น้อง',
  CONTACTNAME: 'ชื่อ',
  CONTACTPHONE: 'เบอร์มือถือ',
  CONTACTRELATION: 'ความสัมพันธ์',
  OTHER: 'ผู้ติดต่ออื่นๆ',
  TAKECARDNAME: 'ชื่อผู้ถือบัตร',
  TAKEID: 'หมายเลขผู้ถือบัตร',
  BANKNAME: 'ชื่อธนาคาร',
  BANKCRAD: 'หมายเลขบัญชี',
  BANKCRADSELF: 'คำเตือน: บัตรธนาคารที่คุณกรอกต้องเป็นบัตรเดบิต (บัตรเดบิต) ภายใต้ชื่อของคุณเท่านั้น',
  INPUTBANKCARDNUMBER: 'หมายเลขบัญชี',
  SAVEDATA: 'เพิ่มข้อมูล',
  BORRORWDETAIL: 'ข้อมูลสินเชื่อ',
  BORRORWPLAN: 'การกำลังดำเนินการขอสินเชื่อ',
  SUBMITSUCCEED: 'ยืนกู้เรียบร้อย',
  ARRIVEMONEYSUCCEED: 'ถึงบัญชีของคุณเรียบร้อย',
  ORDERFORMNUM: 'หมายเลข',
  REFUNDSTATE: 'รายละเอียดการชำระสินเชื่อ',
  NEEDNOTBORRORW: 'ไม่ต้องคืนเงินค่ะ！',
  CURRENTMONTHNEEDNOTBORRORW: 'ไม่ต้องชำระ! คุณไม่มีใบเรียกเก็บเงินที่จะชำระคืนในเดือนนี้',
  NOTE: 'การยืนยันทางSMS',
  NOTENUM: 'ข้อความยืนยันตัวตน',
  NWESPASSWORD: 'รหัสผ่านใหม่',
  INPUTPHONE: 'โปรดกรอกหมายเลขโทรศัพท์',
  INPUTNOTE: 'กรุณากรอกรหัสยืนยันทางSMS',
  INPUTNEWSPASSWORD: 'กรุณาตั้งรหัสผ่าน 6-16หลัก',
  CONFIRM: 'ตกลง',
  PLEAINPUTNAME: 'โปรดกรอกชื่อ',
  PLEAINPUTID: 'กรุณากรอกเลขบัตรประจำตัวประชาชนของคุณ',
  PLEAENTITYNAME: 'กรุณากรอกชื่อบริษัท',
  PLEAPOSITION: 'กรุณากรอกตำแหน่ง',
  PLEAENTITYPHONE: 'กรุณากรอกเบอร์ติดต่อบริษัท',
  PLAEAWORKAGE: 'กรุณากรอกอายุการทำงานของคุณ',
  PLEACURRENTRESIDENCEDETAILS: 'กรุณากรอกที่อยู่ปัจจุบัน',
  PLEACONTACTRELATION: 'พ่อแม่ ลูก',
  BORRORSERIALBUNBER: 'หมายเลขผู้ขอกู้',
  TOTAILMONEY: 'ยอดร่วม',
  RECHARGEMONEYNONTNULL: 'โปรดกรอกยอดเงิน ',
  RECHARGE: 'ข้อมูลการเติม',
  UPLOADVOUCHER: 'โปรดอัพโหลหลักฐาน',
  UPLOADONEPROTO: 'โปรดอัพโหลรูป1ใบ',
  USERREGISTER: 'ลงทะเบียนใช้งาน',
  FORGETPASSWORD: 'ลืมหัสผ่าน',
  NOTNULL: 'กรุณากรอกให้ครบ',
  LENGTH10: 'ห้ามกรอกเลขสั้นกว่า10ตัวเลข',
  REGISTEROK: 'ลงทะเบียนสำเร็จ',
  LOGINOK: 'เข้าสู่ระบบสำเสร็จ',
  AWAIT: 'โปรดรอ',
  THEACCOUNTBALANCE: 'ยอดเงินในบัญชี ',
  UNFILLED: 'ยังไม่ได้',
  UNITADDRESS: 'ที่อยู่บริษัท',
  INPUTUNITADDRESS: 'กรุณากรอกที่อยู่บริษัท',
  OKBORRORW: 'ยืมเงินสำเร็จ',
  PLEAMESSAGECOMPLETE: 'โปรดข้อมูลให้สมบูณ์',
  WORKCERTIFICATE: 'ใบรับรองการจ้างงาน',
  INCOMEPROOF: 'ใบรับรองรายได้',
  NOBRORROW: 'ไม่มีการกู้ยืม',
  MYWITHDRAWDEPOSIT: 'การถอนของฉัน',
  NOMYWITHDRAWDEPOSIT: 'ไม่มีข้อมูลการถอน',
  MYWITHDRAWDEPOSITIME: 'เวลาถอน',
  MYWITHDRAWDEPOSITMONEY: 'ถอนเงินสด',
  MYWITHDRAWDEPOSITDETAILS: 'ข้อมูลในการถอน',
  VIP: 'โปรดเข้าสู่ระบบ',
  LINK: 'ลิงค์',
  STUTA: 'สถาณะ',
  MESSAGE: 'ข้อความ',
  MYWITHDRAWDEPOSILONG: 'ระยะเวลาในการถอน',
  LOADAPP: 'ดาวน์โหลด APP',
  LIJITIXIAN: 'ถอนเงิน',
  TIXIANID: 'หมายเลขผู้ถอน',
  WARMPROMPT: 'แจ้งเตือน',
  CONTACTME: 'ติดต่อเรา',
  QUESTION: 'ตอบถาม',
  TOFAILURE: 'บัญชีผิดพลาด',
  WENXINGTISHI: 'การขอสินเชื่ออยู่ระหว่างดำเนินการโปรดรอสักครู่ การตรวจสอบทางเราจะแจ้งให้คุณทราบเร็ว ๆ นี้ค่ะ',
  OPENVVIP: 'เปิดสมาชิกVIP',
  AWAITJIE: 'จำนวนเงินถอน',
  SEND_SMS: 'ส่งรหัสยืนยัน SMS',
  CUSTOMERSERVICECENTER: 'ศูนย์บริการลูกค้า',
  BORROWINGTIME: 'เวลาขอสินเชื่อ',
  IMGNOT: 'รูปภาพ',
  PLEALOGIN: 'เข้าสู่ระบบ',
  Pleasereviewtheprotocolcarefully: 'โปรดตรวจสอบข้อตกลงนี้',
  Lookatthecontract: 'ตรวจสอบสัญญา',
  FILLINALL: 'โปรดกรอกข้อมูลทั้งหมด',
  WDNUMBER: 'รหัสถอน',
  PWDWDNUMBER: 'กรุณากรอกรหัสถอน',
  EDITTHEAMOUNT: 'แก้ไขสินเชื่อ',
  THENEWPASSWORD: 'กรุณากรอกรหัสใหม่',
  MODIFYTHESUCCESS: 'แก้ไขสำเร้จ',
  MODIFYTHEFAILURE: 'แก้ไขล้มเหลว',
  CONTACTCUSTOMERSERVICE: 'ติดต่อแอดมินค่ะ',
  TIX: 'ถอนเงิน',
  CHECKPASSWORDRe: 'ยืนยันรหัสผ่าน',
  TWICE: 'กรอกรหัสผ่านไม่ตรงกัน',
  HT: 'หนังสือสัญญา'
}
